import React, { useEffect } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { BsChatLeft } from 'react-icons/bs';
import { RiNotification3Line } from 'react-icons/ri'
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Fade, Tooltip } from '@mui/material';

import { Notification, UserProfile, Chat } from './'
import { useStateContext } from '../contexts/ContextProvider';
import { images } from '../constants';

// navbar butto component
const NavbarButton = ({ title, customFunc, icon, color, dotColor, value }) => {
    return (
        <Tooltip title={title} TransitionComponent={Fade}>
            <button type='button' onClick={customFunc}
                style={{ color }}
                className='relative text-xl rounded-full p-3 hover:bg-light-gray'
            >
                <span
                    style={{
                        background: dotColor
                    }}
                    className='absolute inline-flex rounded-full h-3 w-3 right-2 top-1 text-white items-center justify-center text-xs p-2'
                >
                    {value}
                </span>
                {icon}

            </button>
        </Tooltip>
    )
}

const Navbar = () => {
    // states
    const { activeMenu, setActiveMenu, isClicked, setIsClicked, handleClick, screenSize, setScreenSize, currentColor } = useStateContext();

    //getting the screen size
    useEffect(() => {
        const handlerResize = () => setScreenSize(window.innerWidth);

        window.addEventListener('resize', handlerResize)

        handlerResize();

        //removing the eventr listener
        return () => {
            window.removeEventListener('resize', handlerResize)
        }
    }, [])

    //tracking the screen size
    useEffect(() => {
        if (screenSize <= 900) {
            setActiveMenu(false)
        } else {
            setActiveMenu(true)
        }
    }, [screenSize])


    return (
        <div className='flex justify-between p-2 md:mx-6 relative' >
            {/* Menu Icon */}
            <div>
                {!activeMenu &&
                    <NavbarButton
                        title="Menu"
                        customFunc={() => setActiveMenu((prevState) => !prevState)}
                        color={currentColor}
                        icon={<AiOutlineMenu />}
                    />}

            </div>
            {/* Right icons */}
            <div className='flex'>
                {/* chat icon */}
                <NavbarButton
                    title="Chat"
                    customFunc={() => handleClick('chat')}
                    color={currentColor}
                    icon={<BsChatLeft />}
                    dotColor="#03C9D7"
                    value={16}
                />
                {/* notification icon */}
                <NavbarButton
                    title="Notification"
                    customFunc={() => handleClick('notification')}
                    color={currentColor}
                    icon={<RiNotification3Line />}
                    dotColor="red"
                    value={6}
                />
                {/* profile component */}
                <Tooltip title={"Profile"} TransitionComponent={Fade}>
                    <div className='flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg'
                        onClick={() => handleClick('profile')}
                    >
                        <img
                            className='rounded-full h-7 w-7'
                            src={images.avatar} />
                        <p>
                            <span className='text-gray-400 text-14'>Hi, </span>
                            {' '}
                            <span className='text-gray-400 font-bold ml-1 text-14'>James</span>
                        </p>
                        <MdKeyboardArrowDown className='text-gray-400 text-14' />
                    </div>
                </Tooltip>

                {/* is clicked state redirection */}
                {isClicked.chat && <Chat />}
                {isClicked.notification && <Notification />}
                {isClicked.profile && <UserProfile />}

            </div >
        </div >
    )
}

export default Navbar
