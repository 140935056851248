import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import { Close } from '@material-ui/icons';
import Typography from '@mui/material/Typography';
import { chatData, messages } from '../data/Data';
import { TextField } from '@material-ui/core';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

const ShowModal = ({ data }) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button variant="outlined"
                onClick={handleClickOpen}
                sx={{ backgroundColor: 'transparent', border: 0, color: 'blue' }}>View</Button>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Messages
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <div className='flex items-center justify-center'>
                        <div className='bg-main-bg px-4 w-full'>
                            {messages.map((item) => (
                                (item.user_id === data.id &&
                                    <>
                                        {item.incoming &&
                                            <div className='flex items-end justify-end my-8 w-full'>
                                                <span className='w-1/2 flex flex-wrap p-1 bg-slate-300'
                                                    style={{
                                                        borderRadius: '20px 10px 10px 0px '
                                                    }}
                                                >{item.incoming}</span>
                                            </div>}
                                        {item.outgoing &&
                                            <div className='flex items-start justify-start my-8  w-full' >
                                                <span className='w-1/2 flex flex-wrap bg-slate-300 p-1' style={{
                                                    borderRadius: '10px 20px 0px 10px '
                                                }}>{item.outgoing}</span>
                                            </div>}

                                    </>)

                            ))}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className='w-full px-2 flex items-center justify-between  bg-slate-400 rounded-xl h-12 '>
                        <TextField className='w-10/12 mr-2 flex-wrap bg-white' />
                        <Button variant='contained' sx={{ borderRadius: '0px 50px 50px 0px', marginLeft: 1 }} autoFocus onClick={handleClose}>
                            SEND
                        </Button>
                    </div>

                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
export default ShowModal;