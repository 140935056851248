import { Delete } from '@material-ui/icons'
import React from 'react'
import { Header, Table } from '../components'
import { useStateContext } from '../contexts/ContextProvider'
import { chatData, messageGrid } from '../data/Data'

const Message = () => {
    const { currentColor } = useStateContext();

    return (
        <div className='m-2 md:m-6 p-2 md:p-6 bg-white rounded-3xl'>
            <Header category="Page" title="Messages" />
            <Table
                columns={messageGrid}
                data={chatData}
                options={{
                    headerStyle: {
                        backgroundColor: currentColor
                    },
                    sorting: true
                }}
                actions={[
                    {
                        tooltip: 'Remove All Selected Users',
                        icon: Delete,
                        onClick: (evt, data) => alert('You want to delete ' + data.length + ' rows')
                    }
                ]}
            />
        </div>
    )
}

export default Message