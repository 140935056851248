import { Delete, Refresh } from '@material-ui/icons'
import React, { createRef } from 'react'
import { Header, Table } from '../components'
import { useStateContext } from '../contexts/ContextProvider'
import { Data } from '../data'

const Transactions = () => {
    const { currentColor } = useStateContext();
    const tableRef = createRef();
    return (
        <div className='m-2 md:m-6 p-2 md:p-6 bg-white rounded-3xl'>
            <Header category="Page" title="Transactions" />
            <Table
                title={'Transactions Data'}
                columns={Data.transactionGrid}
                data={Data.transactionData}
                options={{
                    selection: true,
                    headerStyle: {
                        backgroundColor: currentColor
                    },
                    sorting: true
                }}
                actions={[
                    {
                        tooltip: 'Remove All Selected Users',
                        icon: Delete,
                        onClick: (evt, data) => alert('You want to delete ' + data.length + ' rows')
                    },
                    {
                        icon: Refresh,
                        tooltip: 'refresh',

                        onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                    }
                ]}
            />
        </div>
    )
}

export default Transactions