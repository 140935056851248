import React from 'react'
import { Header } from '../components'
import NomaKunjeFuneralForm from './NomaKunjeFuneralForm'

const Forms = () => {
    return (
        <div className='m-2 md:m-6 p-2 md:p-6 bg-white rounded-3xl'>
            <Header category="Page" title="Forms" />
            <NomaKunjeFuneralForm />

        </div>
    )
}

export default Forms