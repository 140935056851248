import { images } from "../constants"
import { Modal } from "../components"

import {
    HomeOutlined,
    PeopleAltOutlined,
    PaidOutlined,
    ReportOutlined,
    FeedOutlined,
    RequestQuoteOutlined,
    EmailOutlined,
    FeedbackOutlined,
    MessageOutlined,
    ManageAccountsOutlined
} from "@mui/icons-material"

export const chatData = [
    {
        id: 1,
        image: null,
        title: 'Rorbet sent you message',
        text: 'Need help please?',
        time: '2 mins ago'
    },
    {
        id: 2,
        image: images.person,
        title: 'Merry joined a new policy',
        text: 'Congratulate him/her!!!',
        time: '2 hr'
    },
    {
        id: 3,
        image: images.person,
        title: 'Musa Paid his outstanding balance',
        text: 'Thank him!!!',
        time: '2 mins ago'
    },
    {
        id: 4,
        image: images.avatar,
        title: 'Lucus needs assistance',
        text: 'Please call him',
        time: '2 mins ago'
    },
    {
        id: 5,
        image: images.person,
        title: 'Payment Recieved',
        text: 'send acknoledgement',
        time: '2 mins ago'
    },
]

export const sideBarLinks = [
    {
        name: "Dashbord",
        links: [
            {
                title: 'Home',
                icon: <HomeOutlined />,
                route: 'Home'
            }
        ]
    },
    {
        name: "Quick Links",
        links: [
            {
                title: "Users",
                icon: <PeopleAltOutlined />,
                route: "Users"

            },
            {
                title: "Reports",
                icon: <ReportOutlined />,
                route: "Reports"

            },
            {
                title: "Transactions",
                icon: <PaidOutlined />,
                route: "Transactions"

            },
            {
                title: "Forms",
                icon: <FeedOutlined />,
                route: "Forms"

            },
            {
                title: "Quotations",
                icon: <RequestQuoteOutlined />,
                route: "Quotations"

            },
        ]
    },
    {
        name: "Notifications",
        links: [
            {
                title: 'Mail',
                route: 'Mail',
                icon: <EmailOutlined />,
            },
            {
                title: 'Feedback',
                icon: <FeedbackOutlined />,
                route: 'Feedback'
            },
            {
                title: 'Messages',
                icon: <MessageOutlined />,
                route: 'Messages'
            }
        ]
    },
    {
        name: "Staff",
        links: [
            {
                title: 'Manage',
                icon: <ManageAccountsOutlined />,
                route: "Manage"
            }
        ]

    }
]

export const messages = [
    {
        id: 1,
        user_id: 1,
        incoming: 'Hi sir, Im Tom, could you please help me create a policy',
        outgoing: 'Ok sir, I will call you now'
    },
    {
        id: 2,
        user_id: 1,
        incoming: 'Ok Sir cant wait to take your call',
        outgoing: 'Ok sir, pleasure'
    },
    {
        id: 3,
        user_id: 2,
        incoming: 'Hi, Iam stuck I cannot process payment ',
        outgoing: 'Ok will call you to assist'
    },
    {
        id: 4,
        user_id: 2,
        incoming: 'Hi sir, Im Miss Moreen, could you please help me create a policy',
        outgoing: 'Ok mam, I will call you now'
    },
]
export const themeColors = [
    {
        name: 'primary',
        color: '#1197d5'
    },
    {
        name: 'orange',
        color: '#FAC524'
    },
    {
        name: 'red',
        color: '#ff4000'
    },
    {
        name: 'blue',
        color: '#66ccff'
    },
]

export const reportGrid = [
    {
        title: 'ID',
        field: 'id',
    },
    {
        title: 'Name',
        field: 'name',
    },
    {
        title: 'Surname',
        field: 'surname',
    },
    {
        title: 'Location',
        field: 'location',
    },
    {
        title: 'Description',
        field: 'description',
        sorting: false

    },
    {
        title: 'Image',
        field: 'image',
        render: (params) => <img src={params.image} className="rounded-xl w-40 h-30" alt="ESRIC" />,
        sorting: false
    },
]

export const reportData = [
    {
        id: 1,
        name: 'james',
        surname: "smith",
        location: "Mbabane",
        description: "I got into a car accident and my car was fatally damaged",
        image: images.car
    },
    {
        id: 2,
        name: 'lilly',
        surname: "smith",
        location: "Manzini",
        description: "My house was petrol bombed by unknown people",
        image: images.house
    },
    {
        id: 3,
        name: 'Mandla',
        surname: "Mamba",
        location: "Nhlangano",
        description: "I got into a car accident and my car was fatally damaged",
        image: images.car_accident
    },
    {
        id: 4,
        name: 'Melissa',
        surname: "Gordon",
        location: "Mbabane",
        description: "There was a house break in my home, and everything was stolen",
        image: images.house_break
    },
    {
        id: 5,
        name: 'Julius',
        surname: "Chaung",
        location: "Mbabane",
        description: "I got into a car accident and my car overturned due to loss of breaks",
        image: images.over_turn
    },
    {
        id: 6,
        name: 'Duduzile',
        surname: "Nkabinde",
        location: "Sigwe",
        description: "My house is cracking and it seems to be dangerous to stay on it",
        image: images.house_crack
    },
]

export const usersGrid = [
    { title: 'Id', field: 'id' },
    {
        title: 'Image',
        field: 'avatar',
        render: rowData => (
            <img
                style={{ height: 36, borderRadius: '50%' }}
                src={rowData.image}
                alt='profile'
            />
        ),
        sorting: false
    },
    {
        title: 'First Name',
        field: 'name'
    },
    {
        title: 'Last Name',
        field: 'surname'
    },
    {
        title: "National_ID",
        field: 'id_no',
        sorting: false
    },
    {
        title: "Email",
        field: 'email',
        sorting: false
    },
    {
        title: "Contact No:",
        field: 'contact',
        sorting: false
    },
]

export const userData = [
    {
        id: 1,
        image: images.person,
        name: 'Phumlani',
        surname: 'Chauprai',
        email: 'pchau@yahoo.com',
        id_no: 9809876452562,
        contact: 76857983,
        password: '123pchau'
    },
    {
        id: 2,
        image: images.person,
        name: 'Derrick',
        surname: 'Mumba',
        email: 'dmumba@gmail.com',
        password: 'derrick129',
        id_no: 9809876452562,
        contact: 76857983,
    },
    {
        id: 3,
        image: images.person,
        name: 'Terry',
        surname: 'Cyrus',
        email: 'cyrust@yahoo.com',
        password: '@123cy1',
        id_no: 9809876452562,
        contact: 76857983,
    },
]

export const complaintsGrid = [
    {
        title: 'ID',
        field: 'id',

    },
    {
        title: 'Name',
        field: 'name',

    },
    {
        title: 'Surname',
        field: 'surname',

    },
    {
        title: 'Type',
        field: 'type',

    },
    {
        title: 'Description',
        field: 'description',
        sorting: false
    }
]

export const complainData = [
    {
        id: 1,
        name: "Willy",
        surname: 'Mavrou',
        type: 'suggestion',
        description: "Could you please guys improve in your customer service sector"
    },
    {
        id: 2,
        name: "Willy",
        surname: 'Mavrou',
        type: 'complain',
        description: "I wasn't treated fairly while in your premises"
    },
    {
        id: 3,
        name: "Willy",
        surname: 'Mavrou',
        type: 'suggestion',
        description: "Could you please guys improve in your customer service sector"
    },
    {
        id: 4,
        name: "Willy",
        surname: 'Mavrou',
        type: 'suggestion',
        description: "Could you please guys improve in your customer service sector"
    },
]
export const messageGrid = [
    {
        title: 'ID',
        field: 'id'
    },
    {
        title: 'Image',
        field: 'avatar',
        render: rowData => (
            <img
                style={{ height: 36, borderRadius: '50%' }}
                src={rowData.image ? rowData.image : images.avatar}
                alt='profile'
            />
        ),
    },
    {
        title: 'Heading',
        field: 'title'
    },
    {
        title: 'Recieved',
        field: 'time'
    },
    {
        title: 'Action',
        field: 'button',
        render: rowData => (<Modal data={rowData} />)
    },
]
export const transactionGrid = [
    {
        title: 'ID',
        field: 'id',
        sorting: false
    },
    {
        title: 'Name',
        field: 'name'
    },
    {
        title: 'Surname',
        field: 'surname'
    },
    {
        title: 'Policy',
        field: 'policy'
    },
    {
        title: 'Amount',
        field: 'amount',
        sorting: false
    },
    {
        title: 'Date',
        field: 'date'
    },
]

export const analyticData = [
    {
        name: 'January',
        uv: 4000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: 'February',
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: 'March',
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'April',
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        name: 'Page E',
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        name: 'May',
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'June',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
    {
        name: 'July',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
    {
        name: 'August',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
    {
        name: 'September',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
    {
        name: 'October',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
    {
        name: 'November',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
    {
        name: 'December',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
]

export const transactionData = [
    {
        id: '1',
        name: 'Phumlani',
        surname: 'Matubuko',
        policy: 'Funeral Cover',
        amount: 200,
        date: '12/1/2023'
    },
    {
        id: '2',
        name: 'Philile',
        surname: 'Dlamini',
        policy: 'Funeral Cover',
        amount: 200,
        date: '12/02/2023'
    },
    {
        id: '3',
        name: 'Mandla',
        surname: 'Lushaba',
        policy: 'Mortage',
        amount: 2000,
        date: '12/1/2023'
    },
    {
        id: '4',
        name: 'Wendy',
        surname: 'Matubuko',
        policy: 'Wonkhe Wonkhe',
        amount: 100,
        date: '2/1/2023'
    },
]

export const staffGrid = [
    {
        title: 'ID',
        field: 'id',
        sorting: false
    },
    {
        title: 'Image',
        field: 'avatar',
        render: rowData => (
            <img
                style={{ height: 36, borderRadius: '50%' }}
                src={rowData.image}
                alt='profile'
            />
        ),
        sorting: false
    },
    {
        title: 'Name',
        field: 'name',
    },
    {
        title: 'Surname',
        field: 'surname',
    },
    {
        title: 'Email',
        field: 'email',
        sorting: false
    },
    {
        title: 'Contact_No',
        field: 'contact',
        sorting: false
    },
    {
        title: 'Position',
        field: 'position',
    },
]

export const staffData = [
    {
        id: 1,
        image: images.person,
        name: 'Mike Duven',
        surname: 'Smith',
        email: 'miked@esric.com',
        contact: '76567867',
        position: 'Administrator',
        password: 'mike123'
    },
    {
        id: 2,
        image: images.person,
        name: 'Phumlani',
        surname: 'Chaung',
        email: 'pchau@esric.com',
        contact: '76347867',
        position: 'Administrator',
        password: 'pchau123'
    },
    {
        id: 3,
        image: images.person,
        name: 'Darren',
        surname: 'Mamba',
        email: 'drmamba@esric.com',
        contact: '76535678',
        position: 'Administrator',
        password: 'mdarren123'
    },
]

export default {
    sideBarLinks,
    reportGrid,
    reportData,
    usersGrid,
    userData,
    complaintsGrid,
    complainData,
    themeColors,
    transactionData,
    transactionGrid
}