import React from 'react'
import { MdOutlineCancel } from 'react-icons/md';
import { images } from '../constants';
import { useStateContext } from '../contexts/ContextProvider';
import { chatData } from '../data/Data';
import Button from './Button';

const Notification = () => {
    const { setIsClicked } = useStateContext();
    return (
        <div className='bg-half-transparent w-screen  h-screen fixed nav-item top-0 right-0'>
            <div className='float-right dark: text-gray-2
             bg-white dark:[#484B52] w-400 rounded-lg m-4' >
                <div className='flex justify-between items-center p-4 ml-4 border-b-2'>
                    <p className='font-semibold text-lg'>Notifications </p>
                    <button
                        type='button'
                        onClick={() => setIsClicked({ notification: false })}
                        style={{
                            color: 'rgb(153,171,180)',
                            borderRadius: "50%"
                        }}
                        className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'>
                        <MdOutlineCancel />
                    </button>
                </div>
                {/* Noticfication section */}
                <div>
                    {chatData.map((item, index) => (
                        <div key={index} className='flex items-center p-2 mx-4 border-b-2'>
                            <img src={item.image ? item.image : images.avatar} alt="Item" className='w-12 h-12 rounded-full mx-4' />
                            <div className='justify-start items-start'>
                                <div>
                                    <h5 className='text-14 font-bold'>{item.title}</h5>
                                </div>
                                <div>
                                    <span className='text-sm'>{item.text}</span>
                                </div>
                                <div>
                                    <span className='text-xs'>{item.time}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className='items-center justify-center my-4 flex'>
                        <Button
                            type={'button'}
                            title={'See All Notifications'}
                            onClick={() => { }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notification