import React, { createRef } from 'react'

// my imports
import { Header, Table } from '../components'
import { Data } from '../data'
import { useStateContext } from '../contexts/ContextProvider'
import { Delete, Refresh } from '@material-ui/icons'

const Users = () => {
    const { currentColor } = useStateContext();
    const tableRef = createRef();
    return (
        <div className='m-2 md:m-6 p-2 md:p-6 bg-white rounded-3xl items-center justify-center'>
            <Header category="Page" title="Users" />
            <Table
                title="Users Data"
                columns={Data.usersGrid}
                data={Data.userData}
                options={{
                    headerStyle: {
                        backgroundColor: currentColor
                    }
                }}
                actions={[
                    {
                        tooltip: 'Remove All Selected Users',
                        icon: Delete,
                        onClick: (evt, data) => alert('You want to delete ' + data.length + ' rows')
                    },
                    {
                        icon: Refresh,
                        tooltip: 'refresh',
                        isFreeAction: true,
                        onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                    }
                ]}
            />
        </div>
    )
}

export default Users
