import React from 'react'
import { MdOutlineCancel } from 'react-icons/md'
import { Fade, Tooltip } from '@mui/material'
import { BsCheck } from 'react-icons/bs'

import { useStateContext } from '../contexts/ContextProvider'
import { Data } from '../data'

const ThemeSettings = () => {
    const { setColor, setMode, currentMode, currentColor, setThemeSettings } = useStateContext();
    return (
        <div className='bg-half-transparent w-screen fixed nav-item top-0 right-0'>
            <div className='float-right h-screen dark: text-gray-2
             bg-white dark:[#484B52] w-400'>
                <div className='flex justify-between items-center p-4 ml-4'>
                    <p className='font-semibold text-lg'> Settings</p>
                    <button
                        type='button'
                        onClick={() => setThemeSettings(false)}
                        style={{
                            color: 'rgb(153,171,180)',
                            borderRadius: "50%"
                        }}
                        className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'>
                        <MdOutlineCancel />
                    </button>
                </div>
                {/* theme options */}
                <div className='flex-col border-t-1 border-color p-4 ml-4'>
                    <p className='text-semibold text-lg'> Theme Options</p>
                    <div className='mt-4'>
                        <input
                            type={'radio'}
                            id="light"
                            name='theme'
                            value='light'
                            className='cursor-pointer'
                            onChange={setMode}
                            checked={currentMode === 'Light'} />
                        <label htmlFor='=light' className='ml-2 text-md cursor-pointer'>Light</label>
                    </div>
                    <div className='mt-4'>
                        <input
                            type={'radio'}
                            id="dark"
                            name='theme'
                            value='Dark'
                            className='cursor-pointer'
                            onChange={setMode}
                            checked={currentMode === 'Dark'} />
                        <label htmlFor='dark' className='ml-2 text-md cursor-pointer'>Dark</label>
                    </div>
                </div>

                {/* Themecolors */}
                <div className='flex-col border-t-1 border-color p-4 ml-4'>
                    <p className='text-semibold text-lg'> Theme Colors</p>
                    <div className='flex gap-3'>
                        {Data.themeColors.map((item, index) => (
                            <Tooltip key={index} title={item.name} TransitionComponent={Fade}>
                                <div className='relative mt-2 cursor-pointer flex gap-5 items-center'>
                                    <button
                                        type='button'
                                        onClick={() => setColor(item.color)}
                                        style={{
                                            backgroundColor: item.color
                                        }}
                                        className='h-10 w-10 rounded-full cursor-pointer'>
                                        <BsCheck className={`ml-2 text-2xl text-white ${item.color === currentColor ? 'block' : 'hidden'}`} />
                                    </button>
                                </div>
                            </Tooltip>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ThemeSettings