import React from 'react'
import { Header } from '../components'

const Quotations = () => {
    return (<div className='m-2 md:m-6 p-2 md:p-6 bg-white rounded-3xl'>
        <Header category="Page" title="Quotations Information" />

    </div>

    )
}

export default Quotations