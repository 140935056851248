import React from "react";
import { NavLink } from "react-router-dom";
import { Button, Input } from "../components";
import { images } from "../constants";
import { useFormik } from "formik";
import { basicSchema } from '../schemas'


const SignUp = () => {
    //states from the state context
    const { values, handleChange, errors, handleBlur, handleSubmit } = useFormik({
        initialValues: {
            email: '',
            name: '',
            surname: '',
            password: '',
            confirmPassword: ''
        },
        validationSchema: basicSchema,
        //onSubmit
    })

    return (
        <div className=" flex items-center justify-center min-h-screen bg-gray-100">
            <div className="px-8 py-6 mx-4 mt-4 text-left bg-white shadow-lg md:w-1/3 lg:w-1/3 sm:w-1/3">
                <div className="flex justify-center">
                    <img src={images.logo}
                        className='rounded-lg h-30 w-40'
                    />
                </div>
                <form onSubmit={handleSubmit} autoComplete='off'>
                    <div className="mt-4">
                        <div className="mb-6">
                            <Input
                                type="text"
                                name='name'
                                value={values.name}
                                placeholder=" Enter Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                errorMsg={errors.name}
                            />
                        </div>
                        <div className="mb-6">
                            <Input
                                type="text"
                                value={values.surname}
                                name={'surname'}
                                placeholder="Surname"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                errorMsg={errors.surname}
                            />
                        </div>
                        <div className="mb-6">
                            <Input
                                type="text"
                                value={values.email}
                                name='email'
                                placeholder="Email address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                errorMsg={errors.email}
                            />
                        </div>
                        <div className="mb-6">
                            <Input
                                type="password"
                                name='password'
                                value={values.password}
                                placeholder="Enter Password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                errorMsg={errors.password}
                            />
                        </div>
                        <div className="mb-6">
                            <Input
                                type="password"
                                name='confirmPassword'
                                value={values.confirmPassword}
                                placeholder="Confirm Password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                errorMsg={errors.confirmPassword}
                            />
                        </div>
                        {/* <!-- Submit button --> */}
                        <Button
                            type="submit"
                            title={'Sign Up'}
                            onClick={() => { }}
                        />
                        <div className="flex items-center gap-1 justify-between py-6">
                            <p className="mb-0 mr-2">Already have an account?</p>
                            <button
                                type="button"
                                class="inline-block px-6 border-b-1  py-2 text-blue-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                                data-mdb-ripple="true"
                                data-mdb-ripple-color="light"
                            >
                                <NavLink to={"SignIn"}>
                                    SignIn
                                </NavLink>

                            </button>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    )
}
export default SignUp;