import React from 'react'
import { useStateContext } from '../contexts/ContextProvider';

const FormTitle = ({ heading }) => {
    const { currentColor } = useStateContext();
    return (
        <div>
            <h3 className={`text-2xl md:text-lg text-white py-1 px-4 bold`} style={{ backgroundColor: currentColor }}>{heading}</h3>
        </div>
    )
}

export default FormTitle