import React from 'react'

const Input = ({ type, onBlur, placeholder, onChange, name, errorMsg, value }) => {
    return (
        <div className='flex-column'>
            <input
                type={type}
                value={value}
                className={errorMsg ? "border-red-600" : "form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"}
                placeholder={placeholder}
                onChange={onChange}
                name={name}
                onBlur={onBlur}
            />
            <div>
                {errorMsg}
            </div>

        </div>
    )
}

export default Input