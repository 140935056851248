import * as yup from 'yup'

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;

export const basicSchema = yup.object().shape({
    email: yup.string().email("Please enter a valid email").required("Email address required"),
    password: yup.string().min(5).matches(passwordRules, { message: "Weak password" }).required("password required"),
    name: yup.string().required("name required"),
    surname: yup.string().required("surname required"),
    confirmPassord: yup.string().oneOf([yup.ref('password'), null], "Password must match").required('Password confirmation required')
})

