import { useFormik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'

// my imports
import { Input, Button } from '../components'
import { images } from '../constants'
import { useStateContext } from '../contexts/ContextProvider'


const SignIn = () => {
    // useNavigation to navigate to another page
    const navigate = useNavigate();

    const { setIsLogged } = useStateContext();

    // formik
    const { values, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit: values => {
            if (values.email === "smindlo@gmail.com" && values.password === "123smindlo") {
                setIsLogged(true)
                navigate('/Home');
            }
        },

    });

    return (
        <div className="h-screen flex items-center justify-center">
            <div className="container  flex h-full  items-center justify-center">
                <div className="justify-center items-center g-2 text-gray-800">
                    <div className="mb-10 flex items-center justify-center">
                        <img
                            src={images.logo}
                            className="w-2/4 rounded-full"
                            alt="LOGIN"
                        />
                    </div>
                    <div className="w-full  px-4">
                        <form onSubmit={handleSubmit}>
                            {/* <!-- Email input --> */}
                            <div className="mb-6">
                                <Input
                                    name={"email"}
                                    value={values.email}
                                    type="email"
                                    placeholder="Email address"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </div>

                            {/* <!-- Password input --> */}
                            <div className="mb-6">
                                <Input
                                    type="password"
                                    name={'password'}
                                    value={values.password}
                                    placeholder="Password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </div>

                            {/* <div className="flex justify-between items-center mb-6">
                                <div className="form-group form-check">
                                </div>
                                <div className="text-blue-600 hover:text-blue-700 focus:text-blue-700 active:text-blue-800 duration-200 transition ease-in-out"
                                    onClick={() => { }}
                                >Forgot password?
                                </div>
                            </div> */}

                            {/* <!-- Submit button --> */}
                            <div className='flex items-center justify-center'>
                                <Button
                                    type="submit"
                                    title={'Sign in'}
                                    onClick={handleSubmit}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignIn
