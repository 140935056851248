import React from 'react'

const HomeCard = ({ icon, title, value }) => {
    return (
        <div className='bg-main-bg rounded-lg p-4 '>
            <div className='flex items-center justify-start'>
                {icon}
            </div>
            <div className='mt-2'>
                <p className='font-bold text-gray-400'>{title}</p>
                <p className='text-xl'>{value}</p>
            </div>
        </div>
    )
}

export default HomeCard