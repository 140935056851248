import React from 'react'
import { Formik, Form, Field } from 'formik';
import { TextField, Checkbox } from '@material-ui/core';
import { nomaKunjeFuneralValues } from '../utils';

// MY IMPORTS
import { FormTitle } from '../components';
import { FormControlLabel, FormGroup } from '@mui/material';

const NomaKunjeFuneralForm = () => {

    return (
        <div>
            <div>
                <Formik
                    initialValues={nomaKunjeFuneralValues}>
                    <Form>
                        <div className=' bg-slate-200' >
                            <FormTitle heading='PERSONAL DETAILS' />
                            <div className='px-4'>
                                <div className='flex justify-between flex-wrap'>
                                    <Field name="title" component={TextField} label="Title" />
                                    <Field name="f_name" component={TextField} label="First Name" />
                                    <Field name="s_name" component={TextField} label="Second Name" />
                                    <Field name="surname" component={TextField} label="Surname" />
                                </div>
                                <div className='flex justify-between flex-wrap'>
                                    <Field name="id" style={{ width: "60%" }} component={TextField} label="National ID" />
                                    <Field name="maiden_surname" component={TextField} label="Maiden Surname" />
                                </div>
                                <div className='flex items-center justify-between'>
                                    <Field name="d_o_b" s component={TextField} label="Date of Birth" />
                                    <div className='flex items-center justify-between flex-wrap'>
                                        <span >Gender</span>
                                        <div className='mb-0'>
                                            <FormGroup style={{ display: "flex", flexDirection: 'row', marginLeft: 20, justifyContent: 'space-evenly' }}>
                                                <FormControlLabel control={<Checkbox />} label="Male" />
                                                <FormControlLabel control={<Checkbox />} label="Female" />
                                            </FormGroup>
                                        </div>
                                    </div>

                                </div>
                                <div className='flex justify-between flex-wrap'>
                                    <Field name="id" component={TextField} label="Place of Birth" />
                                    <Field name="id" component={TextField} label="Nationality By Birth" />
                                </div>
                            </div>
                        </div>
                        <div className='mt-4 bg-slate-200'>
                            <FormTitle heading='MERITAL INFORMATION' />
                            <div className='px-4'>
                                <div>
                                    <FormGroup style={{ display: "flex", flexDirection: 'row', marginLeft: 20, justifyContent: 'space-evenly' }}>
                                        <FormControlLabel control={<Checkbox />} label="Single" />
                                        <FormControlLabel control={<Checkbox />} label="Married" />
                                        <FormControlLabel control={<Checkbox />} label="Divorced" />
                                        <FormControlLabel control={<Checkbox />} label="Widowed" />
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                        <div className='mt-4 pb-4 bg-slate-200'>
                            <FormTitle heading='RESITENTIAL ADDRESS' />
                            <div className='px-4'>
                                <Field name="id" style={{ width: '100%' }} component={TextField} label="Current Residential" />
                                <div className='flex justify-between flex-wrap mt-2'>
                                    <Field name="id" style={{ width: '60%' }} component={TextField} label="Current Residential" />
                                    <Field name="id" style={{ width: '30%' }} component={TextField} label="Region" />
                                </div>
                                <Field name='current_citizenship' style={{ width: '100%' }} component={TextField} label='Current Citizenship' />
                                <div className='flex justify-between flex-wrap mt-2'>
                                    <Field name="id" component={TextField} label="Cell No:" />
                                    <Field name="id" component={TextField} label="Work No:" />
                                    <Field name="id" component={TextField} label="Home No:" />
                                </div>
                                <Field name="id" style={{ width: '100%', marginTop: 3 }} component={TextField} label="Email Address" />
                                <div className='flex flex-wrap mt-2 items-center' >
                                    <span className='text-xs'>Do you have any other citizenship(s)</span>
                                    <FormGroup style={{ display: "flex", flexDirection: 'row', marginLeft: 20 }}>
                                        <FormControlLabel control={<Checkbox />} label="Label" />
                                        <FormControlLabel control={<Checkbox />} label="Disabled" />
                                    </FormGroup>
                                </div>
                                <div className='flex flex-wrap mt-2 items-center'>
                                    <span className='text-xs'>If yes (Please state the citizenship(s))</span>
                                    <Field name="id" style={{ width: '50%', marginLeft: 10 }} component={TextField} label="eg.Liswati,Zulu" />
                                </div>
                                <div>
                                    <span className='text-xs'>*Are you or are you related to a business associate to a current and/or former member of Parliament (MP), Senator, Senior Executive of
                                        a state-owned enterprise, a senior-government employee, judicial, military/defence or other such prominent person?</span>
                                    <FormGroup style={{ display: "flex", flexDirection: 'row' }}>
                                        <FormControlLabel control={<Checkbox />} label="Yes" />
                                        <FormControlLabel control={<Checkbox />} label="No" />
                                    </FormGroup>
                                </div>
                                <div className='mt-4'>
                                    <span className='text-xs'>If yes (Please provide details) </span>
                                    <Field name="id" style={{ width: '100%' }} component={TextField} lebel="" />
                                </div>
                                <div className='flex flex-wrap mt-2 items-center' >
                                    <span className='text-xs'>Do you have resident permit</span>
                                    <FormGroup style={{ display: "flex", flexDirection: 'row', marginLeft: 20 }}>
                                        <FormControlLabel control={<Checkbox />} label="Label" />
                                        <FormControlLabel control={<Checkbox />} label="Disabled" />
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                        <div className='mt-4 bg-slate-200'>
                            <FormTitle heading='PROPOSER DETAILS IF LIFE ASSURED IS MINOR/SPOUSE' />
                            <div className='px-4'>
                                <div className='flex justify-between flex-wrap'>
                                    <Field name="title" component={TextField} label="Title" />
                                    <Field name="f_name" component={TextField} label="First Name" />
                                    <Field name="s_name" component={TextField} label="Second Name" />
                                    <Field name="surname" component={TextField} label="Surname" />
                                </div>
                                <div className='flex justify-between flex-wrap'>
                                    <Field name="id" style={{ width: "60%" }} component={TextField} label="National ID" />
                                    <Field name="maiden_surname" component={TextField} label="Maiden Surname" />
                                </div>

                                <div className='flex justify-between flex-wrap'>
                                    <Field name="id" style={{ width: '60%' }} component={TextField} label="Place of Birth" />
                                    <Field name="id" component={TextField} label="Date of Birth" />
                                </div>
                            </div>

                        </div>
                        <div className='mt-4 bg-slate-200'>
                            <FormTitle heading='EMPLOYMENT DETAILS OF PREMIIUM PAYER' />
                            <div className='px-4'>
                                <div>
                                    <FormGroup style={{ display: "flex", flexDirection: 'row', marginLeft: 20, justifyContent: 'space-between' }}>
                                        <FormControlLabel control={<Checkbox />} label="Employed" />
                                        <FormControlLabel control={<Checkbox />} label="Not Employed" />
                                        <FormControlLabel control={<Checkbox />} label="Self-Employed" />
                                        <FormControlLabel control={<Checkbox />} label="Dependant" />
                                    </FormGroup>
                                </div>
                            </div>
                        </div>

                    </Form>
                </Formik>
            </div>
        </div>
    )
}

export default NomaKunjeFuneralForm;