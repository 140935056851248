import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Fade, Tooltip } from '@mui/material';
import { FiSettings } from 'react-icons/fi'
import { createTheme, ThemeProvider } from '@mui/material';
import './App.css';

//my imports
import { useStateContext } from './contexts/ContextProvider';
import { Footer, Navbar, Sidebar, ThemeSettings } from './components'
import {
  Home,
  Feedback,
  Report,
  SignUp,
  SignIn,
  Users,
  Manage,
  Transactions,
  Mail,
  Message,
  Forms,
  Quotations
} from './pages'


const App = () => {
  // states
  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, isLogged } = useStateContext();

  // creating theme constant
  const defaultMaterialTheme = createTheme();
  return (
    <div className={currentMode === 'Dark' ? "dark" : ''}>
      <ThemeProvider theme={defaultMaterialTheme}>
        <BrowserRouter>
          {isLogged ?
            <div className='flex relative dark: bg-main-dark-bg'>
              <div className='fixed right-4 bottom-4' style={{ zIndex: '1000' }}>
                <Tooltip title="Settings" TransitionComponent={Fade} >
                  <button type="button"
                    onClick={() => setThemeSettings(true)}
                    style={{
                      backgroundColor: currentColor,
                      borderRadius: '50%'
                    }}
                    className="text-3xl p-3 hover:drop-shadow-xl hover:bg-light-gray">
                    <FiSettings />
                  </button>
                </Tooltip>
              </div>
              {activeMenu ? (
                <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white">
                  <Sidebar />
                </div>
              ) : (
                <div className="w-0 dark:bg-secondary-dark-bg ">
                  Sidebar
                </div>
              )}
              <div
                className={`${currentMode === 'Dark' ? 'dark' : 'dark:bg-main-bg bg-main-bg'} min-h-screen w-full ${activeMenu ? ' md:ml-72 ' : ' flex-2'}`
                }
              >
                {/* Navbar */}
                <div className="fixed md:static bg-main-bg dark:bg-main-bg navbar w-full">
                  <Navbar />
                </div>

                {/* Routes */}
                <div>
                  {/* theme settings */}
                  {themeSettings && <ThemeSettings />}

                  <Routes>
                    <Route path={'/'} element={<SignIn />} />
                    <Route path={'SignUp'} element={<SignUp />} />
                    <Route path={'Home'} element={<Home />} />
                    <Route path={'Feedback'} element={<Feedback />} />
                    <Route path={'Reports'} element={<Report />} />
                    <Route path={'Users'} element={<Users />} />
                    <Route path={'Transactions'} element={<Transactions />} />
                    <Route path={'Manage'} element={<Manage />} />
                    <Route path={'Mail'} element={<Mail />} />
                    <Route path={'Messages'} element={<Message />} />
                    <Route path={'Forms'} element={<Forms />} />
                    <Route path={'Quotations'} element={<Quotations />} />
                  </Routes>
                </div>
              </div>
            </div>
            : <SignIn />}
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
