import React from 'react'
import { MdOutlineCancel } from 'react-icons/md'
import { images } from '../constants';
import { useStateContext } from '../contexts/ContextProvider'
import Button from './Button';

const UserProfile = () => {
    const { setIsClicked, setIsLogged } = useStateContext();
    return (
        <div className='bg-half-transparent w-screen fixed nav-item top-0 right-0 h-screen'>
            <div className='float-right dark: text-gray-2
             bg-white dark:[#484B52] w-400 rounded-lg m-4' >
                <div className='flex justify-between items-center p-4 ml-4 border-b-2'>
                    <p className='font-semibold text-lg'>Profile </p>
                    <button
                        type='button'
                        onClick={() => setIsClicked({ profile: false })}
                        style={{
                            color: 'rgb(153,171,180)',
                            borderRadius: "50%"
                        }}
                        className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'>
                        <MdOutlineCancel />
                    </button>
                </div>
                <div>
                    <div className='flex items-center p-4 mx-4 border-b-1'>
                        <img src={images.person} alt="profile" className='w-16 h-16 rounded-full mx-4' />
                        <div className='justify-start items-start px-4'>
                            <div>
                                <h5 className='text-14 font-bold'>Phumlani Matubuko</h5>
                            </div>
                            <div>
                                <span className='text-sm'> Administrator</span>
                            </div>
                            <div>
                                <span className='text-xs'>phumatubuko@bpdev.com</span>
                            </div>
                        </div>
                    </div>
                    <div className='flex items-center my-4' >
                        <div className='flex items-center p-2 mx-4'>
                            <img src={images.car} alt="profile" className='w-12 h-12 rounded-xl mx-4' />
                            <div className='justify-start items-start px-1'>
                                <div>
                                    <h5 className='font-bold'>Edit Profile</h5>
                                </div>
                                <div>
                                    <span className='text-xs'>Account Settings</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex items-center my-4' >
                        <div className='flex items-center p-2 mx-4'>
                            <img src={images.car} alt="profile" className='w-12 h-12 rounded-xl mx-4' />
                            <div className='justify-start items-start px-1'>
                                <div>
                                    <h5 className='font-bold'>My Inbox</h5>
                                </div>
                                <div>
                                    <span className='text-xs'>Messages and Emails</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Tasks Section */}
                    <div className='flex items-center my-4' >
                        <div className='flex items-center p-2 mx-4'>
                            <img src={images.car} alt="profile" className='w-12 h-12 rounded-xl mx-4' />
                            <div className='justify-start items-start px-1'>
                                <div>
                                    <h5 className='font-bold'> My Tasks</h5>
                                </div>
                                <div>
                                    <span className='text-xs'>To-do and Daily Tasks</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {/* Log out button */}
                        <div className='flex items-center my-4 justify-center'>
                            <Button title={'Log Out'} onClick={() => setIsLogged(false)} type='button' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserProfile