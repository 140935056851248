import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { MdOutlineCancel } from 'react-icons/md'
import { Fade, Tooltip } from '@mui/material'
import { useStateContext } from '../contexts/ContextProvider'

import { images } from '../constants'
import { Data } from '../data'

const Sidebar = () => {
    // states
    const { activeMenu, setActiveMenu, screenSize, currentColor } = useStateContext();

    //links styles
    const activeLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-white text-md m-2';
    const normalLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700  dark:text-gray-200 dark:hover: text-black hover:bg-light-gray m-2 '

    //fuction to close sidebar
    const handleCloseSidebar = () => {
        if (activeMenu && screenSize <= 900) {
            setActiveMenu(false)
        }
    }

    return (
        <div className='ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10 '>
            {activeMenu && (<>
                <div className='flex justify-between items-center'>
                    {/* company logo */}
                    <Link to={'/'} onClick={handleCloseSidebar}
                        className='items-center ml-3 mt-4'>
                        <img src={images.logo} alt="ESRIC"
                            className='h-14' />
                    </Link>

                    {/* Toggle Sidebar closed button */}
                    <Tooltip title={"Menu"} TransitionComponent={Fade}>
                        <button type="button"
                            onClick={() => setActiveMenu((prevState) => !prevState)}
                            className='text-xl rounded-full  hover:bg-light-gray mt-4 block'>
                            <MdOutlineCancel />
                        </button>
                    </Tooltip>
                </div>

                {/* sidebar items */}
                <div className='mt-10'>
                    {Data.sideBarLinks.map((item) => (
                        <div key={item.name}>
                            <p className='text-gray-400 m-3 mt-4 uppercase'>
                                {item.name}
                            </p>
                            {item.links.map((link) => (
                                <NavLink
                                    to={`/${link.route}`}
                                    onClick={handleCloseSidebar}
                                    key={link.title}
                                    style={({ isActive }) => ({ backgroundColor: isActive ? currentColor : "" })}
                                    className={({ isActive }) =>
                                        isActive ? activeLink : normalLink
                                    }
                                >
                                    {link.icon}

                                    <span
                                        className='capitalize'
                                    >{link.title}
                                    </span>
                                </NavLink>
                            ))}
                        </div>
                    ))}
                </div>
            </>)
            }
        </div >
    )
}

export default Sidebar
