import React from 'react'
import { Delete } from '@material-ui/icons'

// my imports
import { Header, Table } from '../components'
import { Data } from '../data'
import { useStateContext } from '../contexts/ContextProvider'

const Feedback = () => {
    const { currentColor } = useStateContext();
    return (
        <div className='m-2 md:m-6 p-2 md:p-6 bg-white rounded-3xl items-center'>
            <Header category="Page" title="Feedback" />
            <Table
                title={'Feedback Data'}
                columns={Data.complaintsGrid}
                data={Data.complainData}
                options={{
                    headerStyle: {
                        backgroundColor: currentColor
                    }
                }}
                actions={[
                    {
                        tooltip: 'Remove All Selected Users',
                        icon: Delete,
                        onClick: (evt, data) => alert('You want to delete ' + data.length + ' rows')
                    }
                ]}
            />
        </div>
    )
}

export default Feedback
