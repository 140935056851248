import React from 'react'
import { useStateContext } from '../contexts/ContextProvider'

const Button = ({ type, title, onClick }) => {
    const { currentColor } = useStateContext();
    return (
        <button
            type={type}
            onClick={onClick}
            style={{
                backgroundColor: currentColor
            }}
            className="px-7 py-3 text-white font-medium text-sm leading-snug uppercase rounded-xl shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-10/12 self-center"
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
        >
            {title}
        </button>
    )
}

export default Button
