import React from 'react'
import { Header } from '../components'

const Mail = () => {
    return (
        <div className='m-2 md:m-6 p-2 md:p-6 bg-white rounded-3xl'>
            <Header category="Page" title="Mail" />

        </div>
    )
}

export default Mail