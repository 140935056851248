//wonkewonkhe form initial values
export const nomaKunjeFuneralValues = {
    //personal details
    title: '',
    f_name: '',
    s_name: '',
    surname: '',
    m_surname: '',
    id: '',
    gender: '',
    d_o_b: '',
    age_next_birthday: '',
    age_admittedd: '',
    place_of_birth: '',
    nationality_by_birth: '',

    //Marital information
    status: '',

    //Residential info
    current_resintial_address: '',
    postal_address: '',
    current_citizenship: '',
    cell: '',
    work: '',
    home: '',
    email: '',
    other_citizinship: '',
    second_citizenship: '',
    resident_permit: '',
    related: '',
    relation_details: '',


    //Proposer Details 
    p_title: '',
    p_f_name: '',
    p_s_name: '',
    p_surname: '',
    p_m_surname: '',
    p_id: '',
    p_d_o_b: '',
    place_of_birth: '',

    //Proposer Residential info
    p_current_resintial_address: '',
    p_postal_address: '',
    p_current_citizenship: '',
    p_cell: '',
    p_work: '',
    p_home: '',
    p_email: '',
    p_other_citizinship: '',
    p_second_citizenship: '',
    p_resident_permit: '',

    //Employment Details
    emloyment_status: '',
    name_of_employer: '',
    employment_no: '',
    employer_sector: '',
    e_postal_address: '',
    e_physical_address: '',
    occupation: '',
    position: '',
    previous_eployer: '',

    //Self employed
    business_name: '',
    business_nature: '',
    license_no: '',
    self_postal_address: '',
    self_physical_address: '',
    operation_years: '',

    //Bank Deatails
    acc_no: '',
    bank: '',
    holder_name: '',
    brach_name: '',
    branch_code: '',
    prev_earning: '',
    other_income_source: '',
    paymet_method: '',
    document_delivery: '',
    premium_frequency: '',






}