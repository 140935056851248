import logo from '../assets/images/esric1.png';
import avatar from '../assets/images/user.png'
import login from '../assets/images/login.jpg'
import car from '../assets/images/car.jpg'
import car_accident from '../assets/images/car_accident.jpg'
import house from '../assets/images/house.jpg'
import house_break from '../assets/images/house_break.jpg'
import house_crack from '../assets/images/house_crack.jpg'
import over_turn from '../assets/images/over_turn.jpg'
import person from '../assets/images/person.jpg'
export default { logo, avatar, login, car, car_accident, person, house, house_break, house_crack, over_turn }