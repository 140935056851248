import React, { useEffect, useState } from 'react'
import { PaidOutlined, PeopleAltOutlined, RequestQuoteOutlined } from '@mui/icons-material'

// my imports
import { HomeCard, Table } from '../components'
import { analyticData, reportData, reportGrid, transactionData, transactionGrid } from '../data/Data'
import { Area, AreaChart, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

const Home = () => {
    const rcolumns = [
        // {
        //     title: 'Name',
        //     field: 'name',
        // },
        // {
        //     title: 'Surname',
        //     field: 'surname',
        // },
        {
            title: 'Location',
            field: 'location',
        },
        {
            title: 'Description',
            field: 'description',
            sorting: false

        },
        {
            title: 'Image',
            field: 'image',
            render: (params) => <img src={params.image} className="rounded-xl w-16 h-16" alt="ESRIC" />,
            sorting: false
        },
    ];

    const trcolumns = [
        {
            title: 'Name',
            field: 'name'
        },
        {
            title: 'Surname',
            field: 'surname'
        },
        {
            title: 'Policy',
            field: 'policy'
        },
        {
            title: 'Amount',
            field: 'amount',
            sorting: false
        },
        {
            title: 'Date',
            field: 'date'
        },
    ]

    //states 
    const [data, setData] = useState([])
    const [columns, setColumns] = useState(rcolumns)
    const [tdata, setTdata] = useState([]);
    const [tcolumns, setTcolumns] = useState(trcolumns)

    useEffect(() => {
        reportData.reverse()
        setData(reportData.slice(0, 4))

    }, [])

    useEffect(() => {
        transactionData.reverse()
        setTdata(transactionData.slice(0, 5))

    }, [])


    return (
        <div className='m-2 md:m-2 px-6 md:p-2 bg-white rounded-xl'>
            {/* Banner options */}
            <div className='flex flex-wrap lg:flex-nowrap justify-center'>
                <div className='p-4 pt-9 m-2 bg-white rounded-xl dark:text-gray-200 dark:bg-secondary-dark-bg h-44 w-full'>
                    <div className='flex justify-between items-center' >
                        <HomeCard icon={<PaidOutlined sx={{ color: 'blue' }} />} title='Earnings' value={"E120 203.00"} />
                        <HomeCard icon={<PaidOutlined sx={{ color: 'blue' }} />} title='Claims' value={"4 203"} />
                        <HomeCard icon={<PeopleAltOutlined sx={{ color: 'blue' }} />} title='Clients' value={"50 000"} />
                        <HomeCard icon={<PaidOutlined sx={{ color: 'blue' }} />} title='Policy' value={"40"} />
                        <HomeCard icon={<RequestQuoteOutlined sx={{ color: 'blue' }} />} title='Quotes' value={"300"} />
                    </div>
                </div>

            </div>
            {/* charts */}
            <div>
                <div className='flex items-center justify-between'>
                    <h1 className='font-bold text-xl'> Data Analytics</h1>
                </div>
                <ResponsiveContainer width="100%" height="100%" aspect={4 / 1}>
                    <LineChart data={analyticData}>
                        <XAxis dataKey={'name'} stroke="#5550bd" />
                        <YAxis dataKey={'pv'} stroke='#5550bd' />
                        <Line dataKey={'uv'} stroke='#ff00ff' />
                        <Line dataKey={'pv'} stroke='#ff0000' />
                    </LineChart>
                </ResponsiveContainer>
            </div>

            {/* Latest reports and transactions */}
            <div className='flex justify-between items-start md:flex-nowrap sm:flex-wrap mt-8'>
                <div className='mr-2 lg:w-1/2 md:w-1/2'>
                    <Table
                        title='Latest Reports'
                        data={data}
                        columns={columns}
                    />
                </div>
                <div className='ml-2 lg:w-1/2 md:w-1/2'>
                    <Table
                        title='Latest Transactions'
                        data={tdata}
                        columns={tcolumns}
                    />
                </div>
            </div>

        </div >
    )
}

export default Home