import React, { useState } from 'react'


//my imports
import { Header, Table } from '../components'
import { staffData, staffGrid } from '../data/Data';
import { useStateContext } from '../contexts/ContextProvider';

const Manage = () => {
    const { currentColor } = useStateContext();
    const [data, setData] = useState(staffData)
    return (

        <div className='m-2 md:m-6 p-2 md:p-6 bg-white rounded-3xl'>
            <Header category="Page" title="Staff Management" />
            <Table
                title={'Staff Information'}
                columns={staffGrid}
                data={data}
                options={{
                    headerStyle: {
                        backgroundColor: currentColor
                    }
                }}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                setData([...data, newData]);

                                resolve();
                            }, 1000)
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const dataUpdate = [...data];
                                const index = oldData.tableData.id;
                                dataUpdate[index] = newData;
                                setData([...dataUpdate]);

                                resolve();
                            }, 1000)
                        }),
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const dataDelete = [...data];
                                const index = oldData.tableData.id;
                                dataDelete.splice(index, 1);
                                setData([...dataDelete]);

                                resolve();
                            }, 1000)
                        }),
                }}
            />
        </div>
    )
}

export default Manage